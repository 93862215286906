import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  private history: string[] = [];
 
  constructor(private router: Router, private location: Location) {}
  
  addToHistory(url: any){
    this.history.push(url);
  }

  back(failoverUrl:string = '/'): void {
    this.history.pop();
    if (this.history.length > 0) {
      this.location.back();
    } else {
      this.router.navigateByUrl(failoverUrl);
    }
  }
}
